import React, {useEffect} from "react";
import {T_UseCryptohubPaymentState} from "./CryptohubPaymentHooks";
import {RQ} from "app/hooks";
import {ApiConfig} from "app/config";
import {useNotification} from "app/providers";
import Loader from "app/components/unsorted/Loader";
import { Typography } from "@mui/material";


type T_ViewProps = {
    paymentState: T_UseCryptohubPaymentState;
}

type T_AmountDetails = {
    value: number;
    currency: string;
}
export const InitiateTransactionView = ({paymentState}: T_ViewProps) => {
    const notification = useNotification();
    const [retryCount, setRetryCount] = React.useState(0);
    console.log(paymentState.transactionId);

    const apiConfig = ApiConfig.create(
        'crypto-hub-transaction', ['crypto-hub-transaction'],
    );

    const transactionRequest = RQ.usePostRequest({
        url: apiConfig.url,
        mutationKey: apiConfig.key,
    });

    useEffect(() => {
        transactionRequest.EXECUTE({
            payload:{request_uid:paymentState.transactionId},
            onSuccessFn: (data, message) => {
                paymentState.update({
                    transactionLink: data,
                    isTransactionInitiated: true
                });
            },
            onErrorFn: (data, message) => {
                if (message?.toLowerCase().includes('internal server error')) {
                    if (retryCount < 2) {
                        setRetryCount(retryCount + 1);
                    } else {
                        notification.error('An error occurred while initiating transaction. Please try again later.');
                    }
                } else {
                    notification.error(message);
                }
            }
        })
    }, [retryCount]);


    if (transactionRequest.isPending) {
        if(retryCount === 0) {
            return <Loader isLoading={true} />
        } else {
            return (
                <div>
                    <Loader isLoading={true} />
                    <Typography variant="body1">Transaction is taking longer than expected. Please wait...</Typography>
                </div>
            )
        }
    }


    return (<>INITATE PAYMENT</>)
};
